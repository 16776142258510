/**
 * axios setup to use mock service
 */

import axios from 'axios'
import {
    getRefreshToken,
    setRefreshToken,
    setSession,
} from 'contexts/JWTContext'
import { KeyedObject } from 'types'

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    timeout: 30000,
})

const requestArray: KeyedObject[] = []

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => {
        if (requestArray.length !== 0) {
            requestArray.forEach((x: KeyedObject, i: number) => {
                if (response.config.url === x.url) {
                    requestArray.splice(i, 1)
                }
            })
        }

        return response // Return the response for successful requests
    },
    async (error) => {
        if (error.response.status === 401 || error.response.status === 0) {
            try {
                const originalConfig = error.config
                requestArray.push(originalConfig)

                // eslint-disable-next-line no-underscore-dangle
                if (!originalConfig._retry) {
                    // eslint-disable-next-line no-underscore-dangle
                    originalConfig._retry = true

                    const refreshToken = await getRefreshToken()

                    const res = await axiosServices.post(
                        '/auth/refresh-token',
                        {
                            refreshToken,
                        }
                    )

                    const response = res.data

                    if (res.status === 200) {
                        await setSession(response.accessToken)
                        await setRefreshToken(response.refreshToken)

                        if (requestArray.length !== 0) {
                            requestArray.forEach(async (x: KeyedObject) => {
                                try {
                                    axios.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`
                                    axiosServices.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`
                                    await axiosServices(x) // Wait for the request to complete
                                } catch (e: any) {
                                    // Handle errors
                                }
                            })
                        }

                        axiosServices(originalConfig)

                        // Clear the requestArray
                        requestArray.length = 0

                        return await Promise.resolve() // Resolve the Promise after successful token refresh
                    }
                }
            } catch (err) {
                // Handle errors
                return Promise.reject(err)
            }
        } else if (
            (error?.message ?? '') === 'Network Error' ||
            `${error}`.includes('[AxiosError: Network Error]')
        ) {
            localStorage.removeItem('serviceToken')
            localStorage.clear()
            delete axios.defaults.headers.common.Authorization
            alert('Internet not available. Logging out!')
            window.location.reload()
            return Promise.reject(error)
        }

        return Promise.reject(error.response && error.response.data)
    }
)

export default axiosServices
